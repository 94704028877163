import React, { useEffect, useState } from 'react';
import Tab from 'components/common/KtTabs/Tab';
import Tabs from 'components/common/KtTabs/Tabs';
import CONFIG from 'config/configProps';
import { Grid } from 'semantic-ui-react';
import { isEmpty, isLoading } from 'utils/common';
import LabelWithSkeleton from 'components/common/KtLabelWithSkeleton';
import { globalAdobeAnalytics } from 'config/analytics';
import './KTHome.scss';
import {
  isAnalyst,
  isBusinessView,
  isDetailedView,
  isEmptyOrNull,
  isFetchRequired,
  isMemberView,
  getPostDataForBusinessView,
  getDefaultFilters,
  isSuperAdminRoleNew,
} from 'utils';
import KTTeamSize from './TeamSize';
import KTTimesheet from './Timesheet';
import KTTopDownloads from './TopDownloads';
import KTEngagementDepth from './EngagmentDepth';
import KTTopClients from './TopClients';
import KTChargesBillRate from 'components/KTCharges/KTChargesBillRate';
import KTBillabilityTrend from './KTBillabilityTrends';
import KTPulse from './KTPulse';
import KTDownloads from 'components/KTDownloads';
import KTAnalystFilters from 'components/KTAnalystFilters/KTAnalystFilters';
import KTBillability from './KTBillability';
import KTHourlyBillRate from './KTHourlyBillRate';
import KTRms from './KTRms';
import KnMaterials from './KnMaterial';

const KTHome = props => {
  const {
    actions,
    appliedFilters,
    lastUpdatedDate,
    billabilityTrendsDataMember,
    billabilityTrendsDataBusiness,
    timeSheetDataMember,
    timeSheetDataBusiness,
    topDownloadsDataMember,
    topDownloadsDataBusiness,
    topClientsDataMember,
    topClientsDataBusiness,
    ktPulseCheckData,
    engagementDepthDataMember,
    engagementDepthDataBusiness,
    teamSizeData,
    ktBillabilityData,
    hourlyBillRateData,
    cdBillabilityDataMember,
    cdBillabilityDataBusiness,
    topProjectsDataBusiness,
    topProjectsDataMember,
    userDetails,
    selectedView,
    selectedUserByImpersonator,
    impersonatedUserDetails,
    isDataReset,
    rmsSatisfactionDataMember,
    rmsSatisfactionDataBusiness,
    adobeData,
    knMaterialDataMember,
    knMaterialDataBusiness,
  } = props;
  const [activeIndex, setActiveIndex] = useState(
    props?.selectedIndexFromStore || 0
  );
  const [selectedValues, setSelectedValues] = useState({});
  const [apiRequestData, setApiRequestData] = useState({});
  const userData = userDetails.data;
  const loggedInUserRole = !isEmptyOrNull(selectedUserByImpersonator)
    ? impersonatedUserDetails?.data?.results
    : userData?.results.defaultKTRole;
  const loggedInId = !isEmptyOrNull(selectedUserByImpersonator)
    ? selectedUserByImpersonator?.employeeIdHRO
    : userData?.results.hrid;
  const isAnalystUser = isAnalyst(loggedInUserRole);
  const selectedTab = !isEmpty(loggedInUserRole)
    ? CONFIG.PAGE_TABS[loggedInUserRole][activeIndex]
    : '';
  const isDetailedViewOpen = isDetailedView(selectedTab);
  const isMemberViewOpen = isMemberView(
    isEmptyOrNull(selectedView) ? selectedTab : selectedView
  );
  const isBusinessViewOpen = isBusinessView(
    isEmptyOrNull(selectedView) ? selectedTab : selectedView
  );

  useEffect(() => {
    getApiData();
  }, [activeIndex, appliedFilters, userDetails, selectedView]);

  useEffect(() => {
    if (!adobeData?.homePage) {
      const isScriptLoaded = globalAdobeAnalytics(`KTDashboard-HomePage`);
      isScriptLoaded && updateAdobeData();
    }
  }, [adobeData]);

  const updateAdobeData = () => {
    if (!adobeData?.homePage) actions.updateAdobeDetails({ homePage: true });
  };

  const getApiData = (
    selectedKtQustion,
    selectedBillQuestion,
    selectedTopProjectsQuestion
  ) => {
    let dataToPost = {};
    if (isEmptyOrNull(appliedFilters) && !isAnalystUser) return;
    else if (isEmptyOrNull(appliedFilters) && isAnalystUser) {
      dataToPost = getDefaultFilters(loggedInId);
      if (
        isEmptyOrNull(props.practiceArea?.data) &&
        isEmptyOrNull(props.geoMarket?.data)
      ) {
        actions.fetchFiltersData(true, true, false, false, false, dataToPost);
      }
    } else {
      const { startDate, endDate, dateFilterType } = appliedFilters;
      dataToPost = {
        startDate: startDate,
        endDate: endDate,
        dateFilterType:
          !isEmpty(dateFilterType) || appliedFilters?.dateFilterType
            ? dateFilterType
            : 'MONTHLY',
      };
    }
    const loadData = appliedFilters?.isDateRangeChanged || isDataReset;
    if (isEmptyOrNull(dataToPost)) return;
    if (isMemberViewOpen) {
      if (isAnalystUser) dataToPost['selectedUserId'] = [loggedInId];
      else dataToPost['selectedUserId'] = [userData.results.defaultUser];
      if (isEmpty(props.selectedIdMember)) {
        actions.saveMemberId(dataToPost.selectedUserId);
      }
      if (loadData) {
        actions.saveAppliedFilters({
          ...dataToPost,
          isDateRangeChanged: false,
        });
        actions.fetchMemberData(
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          dataToPost
        );
      } else {
        actions.fetchMemberData(
          isFetchRequired(billabilityTrendsDataMember),
          isFetchRequired(topClientsDataMember),
          isFetchRequired(topDownloadsDataMember),
          isFetchRequired(engagementDepthDataMember),
          isFetchRequired(timeSheetDataMember),
          isFetchRequired(hourlyBillRateData),
          isFetchRequired(cdBillabilityDataMember),
          !isEmptyOrNull(selectedTopProjectsQuestion)
            ? true
            : isFetchRequired(topProjectsDataMember),
          isFetchRequired(rmsSatisfactionDataMember),
          isFetchRequired(knMaterialDataMember),
          dataToPost,
          selectedTopProjectsQuestion
        );
      }
    } else if (isDetailedViewOpen) {
      return;
    } else if (isBusinessViewOpen) {
      if (isLoading(props.practiceArea) || isLoading(props.geoMarket)) return;
      if (
        isEmptyOrNull(props.practiceArea) ||
        isEmptyOrNull(props.geoMarket) ||
        isEmptyOrNull(props.defaultUserFilters)
      )
        return;
      if (
        !isEmptyOrNull(selectedKtQustion) ||
        !isEmptyOrNull(selectedBillQuestion) ||
        !isEmptyOrNull(selectedTopProjectsQuestion)
      ) {
        dataToPost = { ...appliedFilters };
      }
      if (
        !isSuperAdminRoleNew(loggedInUserRole) &&
        (!('paIds' in appliedFilters) || !('geoRegion' in appliedFilters))
      ) {
        dataToPost = getPostDataForBusinessView(
          props.practiceArea?.data,
          props.geoMarket?.data,
          dataToPost,
          props.defaultUserFilters?.data,
          isAnalystUser
        );
      } else {
        dataToPost = { ...appliedFilters };
      }
      if (isEmptyOrNull(dataToPost)) return;
      if (!props.isMemberFilterSelected) delete dataToPost.selectedUserId;
      if (loadData) {
        actions.saveAppliedFilters({
          ...dataToPost,
          isDateRangeChanged: false,
        });
        actions.fetchBusinessData(
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          dataToPost
        );
      } else {
        actions.fetchBusinessData(
          isFetchRequired(billabilityTrendsDataBusiness),
          isFetchRequired(topClientsDataBusiness),
          !isEmptyOrNull(selectedKtQustion)
            ? true
            : isFetchRequired(ktPulseCheckData),
          isFetchRequired(engagementDepthDataBusiness),
          isFetchRequired(teamSizeData),
          isFetchRequired(topDownloadsDataBusiness),
          isFetchRequired(timeSheetDataBusiness),
          !isEmptyOrNull(selectedBillQuestion)
            ? true
            : isFetchRequired(ktBillabilityData),
          isFetchRequired(cdBillabilityDataBusiness),
          !isEmptyOrNull(selectedTopProjectsQuestion)
            ? true
            : isFetchRequired(topProjectsDataBusiness),
          isFetchRequired(rmsSatisfactionDataBusiness),
          isFetchRequired(knMaterialDataBusiness),
          dataToPost,
          selectedKtQustion,
          selectedBillQuestion,
          selectedTopProjectsQuestion
        );
      }
    }
    isDataReset && actions.resetData(false);
    setApiRequestData(dataToPost);
  };

  const getKtPulseData = selectedItem => {
    getApiData(selectedItem);
  };

  const getKtBillabilityData = selectedItem => {
    getApiData('', selectedItem);
  };

  const getTopProjectsData = selectedItem => {
    getApiData('', '', selectedItem);
  };

  const getPageData = openView => {
    onDateChange(
      appliedFilters.startDate,
      appliedFilters.endDate,
      appliedFilters.dateFilterType,
      appliedFilters.timePeriodType
    );
    actions.saveSelectedView(openView);
  };

  const onDateChange = (startDate, endDate, dateFilterType, timePeriodType) => {
    let dateAppliedFilters = {
      startDate,
      endDate,
      dateFilterType,
      isDateRangeChanged: true,
      selectedUserId: appliedFilters.selectedUserId,
      timePeriodType,
    };
    actions.saveAppliedFilters(dateAppliedFilters);
  };

  const renderHomePageBody = () => {
    if (isDetailedViewOpen) {
      return (
        <KTChargesBillRate
          selectedIndex={0}
          getDownloadsData={getDownloadsData}
        />
      );
    } else {
      return (
        <Grid className="home_page_main">
          <Grid.Row className="home_page_main_split_row first_row">
            <Grid.Column width={isBusinessViewOpen ? 10 : 9}>
              <KTBillabilityTrend
                data={
                  isBusinessViewOpen
                    ? billabilityTrendsDataBusiness
                    : billabilityTrendsDataMember
                }
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <KTBillability
                data={
                  isBusinessViewOpen
                    ? cdBillabilityDataBusiness
                    : cdBillabilityDataMember
                }
                legends={CONFIG.CD_BILLABILITY_LEGENDS}
                className="cd_billability"
                heading="CD BILLABILITY"
                isCd={true}
              />
              {isBusinessViewOpen ? (
                <KTTimesheet
                  data={timeSheetDataBusiness}
                  isBusinessView={true}
                />
              ) : (
                <KTHourlyBillRate data={hourlyBillRateData} />
              )}
            </Grid.Column>
            <Grid.Column width={isBusinessViewOpen ? 3 : 4}>
              {isBusinessViewOpen ? (
                <KTBillability
                  data={ktBillabilityData}
                  onQuestionChange={getKtBillabilityData}
                  selectedQuestion={props.selectedBillabilityQuestion}
                  legends={CONFIG.KT_BILLABILITY_KEYS}
                  className="kt_billability"
                  heading="KT Benchmark"
                />
              ) : (
                <KTTimesheet data={timeSheetDataMember} />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="home_page_main_split_row">
            <Grid.Column width={4}>
              <KTEngagementDepth
                data={
                  isBusinessViewOpen
                    ? engagementDepthDataBusiness
                    : engagementDepthDataMember
                }
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <KTTopClients
                data={
                  isBusinessViewOpen
                    ? topProjectsDataBusiness
                    : topProjectsDataMember
                }
                isBusinessPage={true}
                heading="TOP PROJECTS"
                className="top_projects"
                showDropDown={true}
                onQuestionChange={getTopProjectsData}
                selectedQuestion={props.selectedTopProjectsQuestion}
                isTopProject={true}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <KTTopClients
                data={
                  isBusinessViewOpen
                    ? topClientsDataBusiness
                    : topClientsDataMember
                }
                isBusinessPage={isBusinessViewOpen || isAnalystUser}
              />
            </Grid.Column>
          </Grid.Row>
          {isBusinessViewOpen ? (
            <Grid.Row className="home_page_main_split_row">
              <Grid.Column width={8}>
                <KTPulse
                  data={ktPulseCheckData}
                  onQuestionChange={getKtPulseData}
                  selectedQuestion={props.selectedKtPulseQuestion}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <KTTeamSize data={teamSizeData} />
              </Grid.Column>
            </Grid.Row>
          ) : null}
          <Grid.Row className="home_page_main_split_row">
            <Grid.Column width={6}>
              <KTTopDownloads
                data={
                  isBusinessViewOpen
                    ? topDownloadsDataBusiness
                    : topDownloadsDataMember
                }
                className="top_downloads"
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <KnMaterials
                data={
                  isBusinessViewOpen
                    ? knMaterialDataBusiness
                    : knMaterialDataMember
                }
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <KTRms
                data={
                  isBusinessViewOpen
                    ? rmsSatisfactionDataBusiness
                    : rmsSatisfactionDataMember
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
  };

  const setIndex = index => {
    setActiveIndex(index);
    const {
      actions: { updateSelectedIndex, fetchUserData },
    } = props;
    updateSelectedIndex(index);
    const apiRequest = {
      employeeIdHRO: loggedInId,
      startDate: apiRequestData.startDate,
      endDate: apiRequestData.endDate,
      dateFilterType: apiRequestData.dateFilterType,
    };
    if (!isAnalystUser) fetchUserData(true, apiRequest);
    getApiData();
  };

  const getDownloadsData = selectedFilters => {
    setSelectedValues(selectedFilters);
  };

  return (
    <Grid className="Kt_main_body">
      <Grid.Row className="Kt_main_body_heading">
        <Grid.Column className="Kt_main_body_heading_left" width={8}>
          <div className="heading_value">
            <div className="heading_value_left">
              <div>Welcome back,&nbsp;</div>
              <div>
                {isLoading(userDetails) ? (
                  <LabelWithSkeleton isLoading size="small" showLine />
                ) : (
                  userData.results.firstName
                )}
              </div>
            </div>
          </div>
          <div className="timeperiod">
            {isEmpty(lastUpdatedDate) ? (
              <LabelWithSkeleton isLoading size="small" showLine />
            ) : (
              `Data as of ${lastUpdatedDate}`
            )}
          </div>
        </Grid.Column>
        {isDetailedViewOpen ? (
          <Grid.Column
            className="Kt_main_body_heading_right"
            width={2}
            floated="right"
          >
            <KTDownloads
              showDownloadModal={true}
              reportName={CONFIG.CHARGES_REPORT_NAMES[0]}
              url={CONFIG.CHARGES_DOWNLOAD_URL[0]}
              pageUrl={`KTDashboard-Charges/Hours by Team Member`}
              isAnalystUser={isAnalystUser}
              loggedInId={loggedInId}
              selectedFilters={selectedValues}
            />
          </Grid.Column>
        ) : null}
      </Grid.Row>
      {isLoading(userDetails) ? (
        <LabelWithSkeleton isLoading size="small" showLine />
      ) : (
        <Grid.Column className="Kt_main_body_tabs" width={16}>
          {isAnalystUser ? (
            <Tabs className="global-tabs" activeTabIndex={activeIndex}>
              {CONFIG.PAGE_TABS[loggedInUserRole].map((tab, index) => {
                return (
                  <Tab
                    key={tab}
                    tabText={tab}
                    linkClassName="custom-link"
                    tabIndex={index}
                    handleTabClick={index => setIndex(index)}
                  >
                    <KTAnalystFilters
                      userDetails={
                        !isEmpty(selectedUserByImpersonator)
                          ? selectedUserByImpersonator
                          : userData.results
                      }
                      practiceArea={props.practiceArea}
                      geoMarket={props.geoMarket}
                      onDropDownChange={getPageData}
                      isBusinessView={isBusinessViewOpen}
                      onDateChange={onDateChange}
                      appliedFilters={appliedFilters}
                      isDetailedViewOpen={isDetailedViewOpen}
                    />
                    {renderHomePageBody()}
                  </Tab>
                );
              })}
            </Tabs>
          ) : (
            renderHomePageBody()
          )}
        </Grid.Column>
      )}
    </Grid>
  );
};

export default KTHome;

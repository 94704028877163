import React, { PureComponent } from 'react';
import KTCheckbox from 'components/common/KTCheckbox';
import './AdminFilter.scss';
import { isEmpty, isEqual } from 'utils/common';
import KtSearchSmall from '../KtSearchSmall';
import { onFiltersDropDownOpen, onFiltersInteractions } from 'config/analytics';

class KtDdFilters extends PureComponent {
  constructor(props) {
    super(props);
    const { selectedList, dataList, isAllSelected, singleSelect } = this.props;
    this.state = {
      selectedList: isAllSelected ? this.getAllIds() : selectedList,
      noData: false,
      isAllSelected: singleSelect ? false : isAllSelected,
      dataList: dataList,
      dataListToBeShown: dataList,
      selectedName: '',
    };
  }

  componentDidMount() {
    onFiltersDropDownOpen(`${this.props.filterName} Filter Opened`);
  }

  getAllIds = () => {
    const length = this.props.dataList.length;
    if (length > 1)
      return [{ value: '-1' }, ...this.props.dataList].map(el => el.value);
    else if (length === 1) return [this.props.dataList[0].value];
  };

  handleRadioChange = selectedItem => {
    this.setState(
      { selectedList: [selectedItem.value], selectedName: selectedItem.label },
      () => {
        this.applyFilter();
      }
    );
  };

  toggleAllSelection = (isAllSelected, dataList) => {
    let allSelected;
    let selectedIds;

    if (isAllSelected) {
      selectedIds = {};
      allSelected = false;
    } else {
      dataList = dataList.filter(obj => {
        return obj.value !== '-1';
      });
      selectedIds = dataList.reduce(
        (acc, el) => ({ ...acc, [el.value]: true }),
        {}
      );
      allSelected = true;
    }

    return [allSelected, selectedIds];
  };

  checkIdInAlllist = (id, allSelected, allOptions) => {
    return allSelected && allOptions?.find(el => id === el.paId?.toString());
  };

  checkAllSelected = (selectedIds, allSelected, allOptions) => {
    return !allSelected && allOptions?.every(el => selectedIds[el.paId]);
  };

  toggleSingleOptionSelection = (id, selectedIds) => {
    let isAllSelected;

    if (selectedIds[id]) {
      if (selectedIds['-1']) {
        delete selectedIds['-1'];
      }

      isAllSelected = false;
      delete selectedIds[id];
    } else {
      selectedIds[id] = true;
    }

    return [isAllSelected, selectedIds];
  };

  handleCheckboxChange = id => {
    const { selectedList, dataList } = this.state;
    let selectedIds = selectedList.reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {}
    );
    let isAllSelected = this.state.isAllSelected || selectedIds['-1'];

    if (isEqual(id, '-1')) {
      [isAllSelected, selectedIds] = this.toggleAllSelection(
        isAllSelected,
        dataList
      );
    } else {
      [isAllSelected, selectedIds] = this.toggleSingleOptionSelection(
        id,
        selectedIds
      );
    }
    this.setState({
      selectedList: Object.keys(selectedIds),
      isAllSelected,
    });
  };

  clearFilter = () => {
    this.setState({
      selectedList: [],
      isAllSelected: false,
    });
    this.props.clearFilter(this.props.stateField, [], 0);
  };

  applyFilter = () => {
    const { dataList, stateField, onChange, filterName } = this.props;
    const { selectedList, selectedName } = this.state;
    const selectedListLength = selectedList.length;
    const dataListLength = dataList.length;
    const length =
      selectedListLength > dataListLength ? dataListLength : selectedListLength;
    onFiltersInteractions(filterName, `${filterName} Filter applied`);
    return onChange(stateField, selectedList, length, selectedName);
  };

  showSearchResults = value => {
    if (value) {
      let data = this.state.dataList;
      let searchResultData = [];
      searchResultData = data.filter(el => {
        return el.label.toLowerCase().includes(value.toLowerCase());
      });

      this.setState({
        dataListToBeShown: searchResultData,
      });
    } else {
      this.setState({
        dataListToBeShown: this.state.dataList,
      });
    }
  };

  renderDropDown = (list, checked) => {
    const { singleSelect } = this.props;
    const { selectedList } = this.state;
    return list.map(el => {
      return (
        <div
          key={el.value}
          className={`filter-display ${
            singleSelect ? 'singleSelectFilter' : ''
          } ${checked ? 'highlighted' : ''}`}
        >
          {singleSelect ? null : (
            <KTCheckbox
              checked={checked || selectedList.includes(el.value)}
              checkboxColor="darkest-blue"
              handleChange={this.handleCheckboxChange}
              isReturn={true}
              value={el.value}
              name={el.label}
            />
          )}
          <div
            className={`filter-name ${singleSelect ? 'singleSelect' : ''}`}
            onClick={() => this.handleRadioChange(el)}
          >
            {el.label}
          </div>
        </div>
      );
    });
  };

  render() {
    const { selectedList, noData, dataListToBeShown, isAllSelected } =
      this.state;
    const {
      singleSelect,
      showSearch,
      placeHolder,
      searchPosition,
      hideButtonFooter,
      showSelectedOnTop,
      className,
    } = this.props;
    const disabled = noData || isEmpty(selectedList);
    const selectedDropDownList = dataListToBeShown.filter(el =>
      selectedList.includes(el.value)
    );
    const uniqueItems = dataListToBeShown.filter(
      el => !selectedList.includes(el.value)
    );
    return (
      <div className={`${className} admin-filter-container`}>
        {showSearch && (
          <KtSearchSmall
            containerClassName="filter-list-search"
            onChangeHandler={this.showSearchResults}
            placeholder={placeHolder ? placeHolder : 'Search'}
            searchPosition={searchPosition}
          />
        )}
        <div className={`filter-list ${singleSelect ? 'showMaxHeight' : ''}`}>
          {!singleSelect && dataListToBeShown.length > 1
            ? this.renderDropDown(
                [{ value: '-1', label: 'All' }],
                isAllSelected
              )
            : null}
          {showSelectedOnTop ? (
            <>
              {this.renderDropDown(selectedDropDownList, true)}
              {this.renderDropDown(uniqueItems, false)}
            </>
          ) : (
            this.renderDropDown(dataListToBeShown)
          )}
        </div>
        {hideButtonFooter ? null : (
          <div className="button-footer">
            <div className="clear" onClick={this.clearFilter.bind(this)}>
              Clear
            </div>
            <div
              className={`apply ${disabled ? 'disabled' : ''}`}
              onClick={e => {
                e.nativeEvent.stopImmediatePropagation();
                this.applyFilter();
              }}
            >
              Apply
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default KtDdFilters;

import React, { useEffect, useState } from 'react';
import { batch, connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import CONFIG from 'config/configProps';
import { fetchPost } from 'utils/KtCommonServices';
import { isEqual, times } from 'utils/common';
import KTDetailedView from './KTDetailView';
import KTBillabilityLoader from 'components/common/KTBillabilityLoader';
import KTBillRate from './KTBillRate';
import { isEmptyOrNull, getPostData, isAnalyst } from 'utils';
import { useHistory } from 'react-router-dom';
import DropDownFilter from 'components/common/AdminFilter/KtDdFilters';

const KTChargesBillRate = props => {
  const {
    selectedIndex,
    appliedFilter,
    memberList,
    impersonatedUserDetails,
    userDetails,
    selectedUserByImpersonator,
  } = props;
  const history = useHistory();

  const defaultSortingOption = `${CONFIG.PROTOTYPE_CHARGES_DEFAULT_SORT_OPTIONS[selectedIndex]}Desc`;
  let defaultValue = {};
  defaultValue[defaultSortingOption] = true;
  const [showLoader, setShowLoader] = useState(false);
  const [apiData, setApiData] = useState();
  const [selectedOption, setSelectedOption] = useState({});
  const [showSortingOptions, setShowSortingOptions] = useState(defaultValue);
  const [showTooltip, setShowToolTip] = useState({});
  const [openedFilters, setOpenedFilters] = useState({
    [CONFIG.CHARGES_FILTERS_COLUMNS[0]]: false,
    [CONFIG.CHARGES_FILTERS_COLUMNS[1]]: false,
  });
  const [selectedValues, setSelectedValues] = useState({
    [CONFIG.CHARGES_FILTERS_COLUMNS[0]]: [],
    [CONFIG.CHARGES_FILTERS_COLUMNS[1]]: [],
  });
  const [apiRequestData, setApiRequestData] = useState();

  const loggedInUserRole = !isEmptyOrNull(impersonatedUserDetails?.results)
    ? impersonatedUserDetails?.results
    : userDetails?.defaultKTRole;
  const loggedInId = !isEmptyOrNull(selectedUserByImpersonator)
    ? selectedUserByImpersonator.employeeIdHRO
    : userDetails?.hrid;
  const isAnalystUser = isAnalyst(loggedInUserRole);
  if (isAnalystUser) {
    if (isEqual(selectedIndex, 1)) history.push(CONFIG.PROTOTYPE_URLS.HOME);
  }

  useEffect(() => {
    setShowLoader(true);
    setApiData();
    getApiData();
  }, [selectedIndex, appliedFilter]);

  const getApiData = selectedFilters => {
    const postData = getPostData(appliedFilter, memberList, isAnalystUser);
    if (isEmptyOrNull(postData)) return;
    if (!isEmptyOrNull(selectedFilters)) {
      postData['utilizationCategories'] =
        selectedFilters[CONFIG.CHARGES_FILTERS_COLUMNS[0]];
      postData['projectTypes'] =
        selectedFilters[CONFIG.CHARGES_FILTERS_COLUMNS[1]];
    }
    if (isAnalystUser) postData.selectedUserId = [loggedInId];
    if (isEqual(postData, apiRequestData)) return;
    const baseUrl = CONFIG.KT_BILLING_API_URL;
    let endpoint = '',
      endPointTotal = '';
    if (isEqual(selectedIndex, 0)) {
      endpoint = CONFIG.PROTOTYPE_API_URLS.CHARGES_HOURS;
      endPointTotal = CONFIG.PROTOTYPE_API_URLS.CHARGES_HOURS_TOTAL;
    } else {
      endpoint = CONFIG.PROTOTYPE_API_URLS.BILL_RATE;
    }
    batch(() => {
      fetchPost(endpoint, postData, baseUrl).then(response => {
        let apiResponse = response?.data;
        endPointTotal
          ? fetchPost(endPointTotal, postData, baseUrl).then(response => {
              const resp = response?.data;
              apiResponse.EmployeeTotal = ['Total', [resp.chargesTotal]];
              setApiData(apiResponse);
              setApiRequestData(postData);
            })
          : setApiData(apiResponse);
        setShowLoader(false);
      });
    });
  };

  const renderChartDataTable = () => {
    const propPack = {
      apiData: apiData,
      selectedOption: selectedOption,
    };
    switch (selectedIndex) {
      case 0:
        return <KTDetailedView {...propPack} />;
      case 1:
        return <KTBillRate {...propPack} />;
      default:
        return null;
    }
  };

  const onFilterChange = (selectedName, selectedValue) => {
    let selectedFilters = { ...selectedValues, [selectedName]: selectedValue };
    setSelectedValues(selectedFilters);
    setOpenedFilters({});
    getApiData(selectedFilters);
    props.getDownloadsData(selectedFilters);
  };

  const renderBillabilityChart = () => {
    let headerColumns = CONFIG.PROTOTYPE_CHARGES_HOURS_COLUMNS;
    headerColumns = isEqual(selectedIndex, 0)
      ? headerColumns.detailedView
      : headerColumns.billRate;

    return (
      <Table
        basic
        celled
        sortable
        compact
        id="spark-line-table"
        className="kt-billability-container__chart__table"
      >
        <Table.Header className="kt-billability-container__chart__table__header">
          <Table.Row>
            {Object.keys(headerColumns).map(col => {
              const tooltipDescClassName = showTooltip[`${col}ToolTipDesc`]
                ? 'on'
                : 'off';
              const tooltipAccClassName = showTooltip[`${col}ToolTipAcc`]
                ? 'on'
                : 'off';
              return (
                <Table.HeaderCell key={col}>
                  <div className="heading-content">
                    <div className="heading">{col}</div>
                    {isEqual(selectedIndex, 0) &&
                      CONFIG.CHARGES_FILTERS_COLUMNS.indexOf(col) > -1 && (
                        <div
                          className="filter-icon"
                          onClick={() =>
                            setOpenedFilters({
                              [col]: !openedFilters[col],
                            })
                          }
                        >
                          {times(3, i => (
                            <div className="filter-icon__dot" key={i}>
                              .
                            </div>
                          ))}
                        </div>
                      )}
                    {openedFilters[col] && (
                      <DropDownFilter
                        dataList={CONFIG.CHARGES_HOURS_FILTERS_LIST[col]}
                        selectedList={selectedValues[col]}
                        className="padding-10"
                        onChange={onFilterChange}
                        clearFilter={onFilterChange}
                        isAllSelected={isEqual(
                          CONFIG.CHARGES_HOURS_FILTERS_LIST[col].length || 0,
                          selectedValues?.length || 0
                        )}
                        showSearch={false}
                        stateField={col}
                      />
                    )}
                    {isEqual(selectedIndex, 1) && (
                      <span
                        className="tooltip-with-icon periodTooltip desc"
                        onMouseEnter={() => {
                          const newState = {};
                          newState[`${col}ToolTipDesc`] = true;
                          setShowToolTip({ ...newState });
                        }}
                        onMouseLeave={() => {
                          const newState = {};
                          newState[`${col}ToolTipDesc`] = false;
                          setShowToolTip({ ...newState });
                        }}
                      >
                        <div
                          className={`headerSortUp ${
                            showSortingOptions[`${col}Desc`] ? 'blueBgUp' : ''
                          }`}
                          onClick={() => {
                            const newState = {};
                            newState[`${col}Desc`] = true;
                            newState[`${col}Acc`] = false;
                            setSelectedOption({ value: col, type: 'Desc' });
                            setShowSortingOptions({ ...newState });
                          }}
                        />
                        <div id="tooltip" className={tooltipDescClassName}>
                          <div className="tooltip-inner">
                            {isEqual(headerColumns[col], 'number')
                              ? CONFIG.SORTING_TOOLTIP_TEXT.numberDesc
                              : CONFIG.SORTING_TOOLTIP_TEXT.StringDesc}
                          </div>
                        </div>
                      </span>
                    )}
                    {isEqual(selectedIndex, 1) && (
                      <span
                        className="tooltip-with-icon periodTooltip acc"
                        onMouseEnter={() => {
                          const newState = {};
                          newState[`${col}ToolTipAcc`] = true;
                          setShowToolTip({ ...newState });
                        }}
                        onMouseLeave={() => {
                          const newState = {};
                          newState[`${col}ToolTipAcc`] = false;
                          setShowToolTip({ ...newState });
                        }}
                      >
                        <div
                          className={`headerSortDown ${
                            showSortingOptions[`${col}Acc`] ? 'blueBgDown' : ''
                          }`}
                          onClick={() => {
                            const newState = { ...showSortingOptions };
                            newState[`${col}Acc`] = true;
                            newState[`${col}Desc`] = false;
                            setSelectedOption({ value: col, type: 'Acc' });
                            setShowSortingOptions({ ...newState });
                          }}
                        />
                        <div id="tooltip" className={tooltipAccClassName}>
                          <div className="tooltip-inner">
                            {isEqual(headerColumns[col], 'number')
                              ? CONFIG.SORTING_TOOLTIP_TEXT.numberAcc
                              : CONFIG.SORTING_TOOLTIP_TEXT.StringAcc}
                          </div>
                        </div>
                      </span>
                    )}
                  </div>
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body className="kt-billability-container__chart__table__body">
          {isEmptyOrNull(apiData) ||
          (isEqual(selectedIndex, 0) && isEmptyOrNull(apiData?.employeeMap)) ? (
            <div>No Record to display</div>
          ) : (
            renderChartDataTable()
          )}
        </Table.Body>
      </Table>
    );
  };

  return (
    <div className="kt-billability-container">
      <div className="kt-billability-container__chart">
        {showLoader ? <KTBillabilityLoader /> : renderBillabilityChart()}
      </div>
    </div>
  );
};
const mapStateToProps = store => {
  return {
    appliedFilter: store.filterDataPt.filters,
    memberList: store.filterDataPt.fetchTeamMemberList?.results || [],
    userDetails: store.homePageData.fetchUserDetails?.results || {},
    impersonatedUserDetails: store.homePageData.fetchImpersonatedUserDetails,
    selectedUserByImpersonator: store.homePageData.selectedUserByImpersonator,
  };
};

export default connect(mapStateToProps)(KTChargesBillRate);
